/*!
=========================================================
* Muse Ant Design Dashboard - v1.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
* Coded by Creative Tim
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
@media (min-width: 992px) {
  .layout-dashboard .ant-layout-footer {
    margin: 0 0 20px 20px;
  }
  .layout-dashboard .ant-layout-sider.sider-primary {
    /* margin: 20px 0 0 20px; */
    padding: 1px 1px !important;
    /* height: calc(100vh - 20px); */
  }
  .layout-dashboard .ant-layout {
    width: auto;
    flex-shrink: 1;
    margin-right: 420px;
  }
  .layout-dashboard .header-control .sidebar-toggler {
    display: none;
  }
  .ant-drawer.drawer-sidebar.ant-drawer-open {
    display:none;
    }
  /* .select-order{
    width: 0%;
    padding-top: 80px;
    position: absolute;
    right: 0px;
    padding-left: 10%!important;
    padding-right: 10% !important;
  } */
}

@media (min-width: 768px) {
  /* .layout-dashboard .ant-layout-header {
    margin: 0px 20px;
  } */
  .layout-dashboard .header-control {
    margin-top: 0;
  }
  .layout-dashboard .header-control .header-search {
    margin: 0 7px;
  }
  /* .layout-dashboard .header-control .btn-sign-in span {
    display: inline;
  } */
  .profile-nav-bg {
    margin-top: -87.8px;
  }
  .card-profile-head {
    margin: -53px 24px 24px;
  }
  /* .select-order{
    width: 0%;
    padding-top: 80px;
    position: absolute;
    right: 0px;
    padding-left: 10%!important;
    padding-right: 10% !important;
  } */
}
@media (min-width: 992px) {
  .card-billing-info.ant-card .ant-card-body {
    display: flex;
  }
  .layout-dashboard-rtl {
    overflow: auto;
  }
  .layout-dashboard-rtl .ant-layout-sider.sider-primary {
    margin: 20px 20px 0 0;
    height: calc(100vh - 20px);
  }
  .layout-dashboard-rtl .ant-layout-sider.sider-primary {
    right: 0;
    left: auto;
  }
  .layout-dashboard-rtl .ant-layout-sider.sider-primary {
    right: 0;
    left: auto;
  }
  .layout-dashboard-rtl .ant-layout {
    margin-right: 270px;
    margin-left: 0;
  }
  .layout-dashboard-rtl .ant-layout-footer {
    margin: 0 20px 20px 0;
  }
  /* .select-order{
    width: 0%;
    padding-top: 80px;
    position: absolute;
    right: 0px;
    padding-left: 10%!important;
    padding-right: 10% !important;
  } */
}

@media only screen and (device-width : 320px) 
and (device-height : 568px)  {
  .layoutContainer {
    height: calc(100vh - 20%);
    width: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow-y: auto;
  }
  .select-order{
    width: 100% !important;
    position: absolute !important;
    right: 0px !important;
    height: 0% !important;
    padding-left: 10%!important;
    padding-right: 10% !important;
    padding-top: 100px !important;
}

.sign-img{
  display: none !important;
}
.tip_amount {
  margin-top: 1px !important;
  padding-left: 15px
}
.item-list {
  max-height: 195px !important;
  overflow: hidden !important;
  overflow-y: scroll !important;
}

.item-list-order-status {
  max-height: 250px !important;
  overflow: hidden !important;
  overflow-y: scroll !important;
}
.MuiListItem-rootTotal {
  width: 100% !important;
  display: flex !important;
  position: relative !important;
  box-sizing: border-box !important;
  text-align: left !important;
  align-items: center !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  justify-content: flex-start !important;
  text-decoration: none !important;
}

.MuiList-padding {
  padding-top: 0px !important;
  padding-bottom: 8px !important;
}
.layout-dashboard .ant-layout-header {
  background: transparent;
  height: auto;
  padding-top:0px;
  padding-bottom:0px;
  padding-left:20px;
  padding-right: 20px;
  line-height: inherit;
  border-radius: 12px;
  transition: 0.2s;
}
.layout-default.layout-signin header.ant-layout-header {
  position:fixed;
  top:0;
  left:0;
  right:0;
  z-index:2;
  background-color: #fafafa;
  padding: 0 16px;
}
}

@media (max-width: 768px) {
  .layout-dashboard.layout-dashboard-rtl .header-control {
    justify-content: flex-end;
  }

.MuiTabs-centered {
    justify-content: flex-start !important;
}
  /* .select-order{
    width: 0%;
    padding-top: 80px;
    position: absolute;
    right: 0px;
    padding-left: 10%!important;
    padding-right: 10% !important;
  } */
}
@media only screen 
    and (device-width : 375px) 
    and (device-height : 812px) 
    and (-webkit-device-pixel-ratio : 3) { 
      .layoutContainer {
        height: calc(100vh - 20%);
        width: 100%;
        display: flex;
        flex: 1;
        flex-direction: column;
        overflow-y: auto;
      }
  .select-order{
        width: 100% !important;
        position: absolute !important;
        right: 0px !important;
        height: 0% !important;
        padding-left: 10%!important;
        padding-right: 10% !important;
        padding-top: 100px !important;
  }

  .sign-img{
    display: none !important;
  }

  .tip_amount {
    margin-top: 1px !important;
    padding-left: 15px
  }

  .layout-dashboard .ant-layout-sider.sider-primary {
    /* margin: 20px 0 0 20px; */
    padding: 2px 0px;
    height: calc(100vh - 20px);
  }
  .layout-dashboard .ant-layout-header {
    background: transparent;
    height: auto;
    padding-top:0px;
    padding-bottom:0px;
    padding-left:20px;
    padding-right: 20px;
    line-height: inherit;
    border-radius: 12px;
    transition: 0.2s;
  }
  .layout-default.layout-signin header.ant-layout-header {
    position:fixed;
    top:0;
    left:0;
    right:0;
    z-index:2;
    background-color: #fafafa;
    padding: 0 16px;
  }

    }

    @media only screen 
    and (device-width : 1024px) 
    and (device-height : 600px) { 
  .select-order{
        width: 100% !important;
        position: absolute !important;
        right: 0px !important;
        height: 0% !important;
        padding-left: 10%!important;
        padding-right: 10% !important;
        padding-top: 100px !important;
  }

  .sign-img{
    display: none !important;
  }
  .tip_amount {
    margin-top: 1px !important;
    padding-left: 15px
  }
  .layout-dashboard .ant-layout-sider.sider-primary {
    /* margin: 20px 0 0 20px; */
    padding: 2px 0px;
    height: calc(100vh - 20px);
  }

  .item-list {
    max-height: 190px !important;
    overflow: hidden !important;
    overflow-y: scroll !important;
  }

  .item-list-order-status {
    max-height: 245px !important;
    overflow: hidden !important;
    overflow-y: scroll !important;
  }
  
  .MuiListItem-rootTotal {
    width: 100% !important;
    display: flex !important;
    position: relative !important;
    box-sizing: border-box !important;
    text-align: left !important;
    align-items: center !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    justify-content: flex-start !important;
    text-decoration: none !important;
  }
  
  .MuiList-padding {
    padding-top: 0px !important;
    padding-bottom: 8px !important;
  }
  .layout-dashboard .ant-layout-header {
    background: transparent;
    height: auto;
    padding-top:0px;
    padding-bottom:0px;
    padding-left:20px;
    padding-right: 20px;
    line-height: inherit;
    border-radius: 12px;
    transition: 0.2s;
  }
  .layout-default.layout-signin header.ant-layout-header {
    position:fixed;
    top:0;
    left:0;
    right:0;
    z-index:2;
    background-color: #fafafa;
    padding: 0 16px;
  }
    }

    @media only screen 
    and (device-width : 1280px) 
    and (device-height : 800px) { 
  .select-order{
        width: 100% !important;
        position: absolute !important;
        right: 0px !important;
        height: 0% !important;
        padding-left: 10%!important;
        padding-right: 10% !important;
        padding-top: 100px !important;
  }

  .sign-img{
    display: none !important;
  }

  .tip_amount {
    margin-top: 15px !important;
    padding-left: 15px
  }
  .layout-dashboard .ant-layout-sider.sider-primary {
    /* margin: 20px 0 0 20px; */
    padding: 2px 0px;
    height: calc(100vh - 20px);
  }

  .item-list {
    max-height: 330px !important;
    overflow: hidden !important;
    overflow-y: scroll !important;
  }

  .item-list-order-status {
    max-height: 395px !important;
    overflow: hidden !important;
    overflow-y: scroll !important;
  }
  
  .MuiListItem-rootTotal {
    width: 100% !important;
    display: flex !important;
    position: relative !important;
    box-sizing: border-box !important;
    text-align: left !important;
    align-items: center !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    justify-content: flex-start !important;
    text-decoration: none !important;
  }
  
  .MuiList-padding {
    padding-top: 0px !important;
    padding-bottom: 8px !important;
  }
  .layout-dashboard .ant-layout-header {
    background: transparent;
    height: auto;
    padding-top:0px;
    padding-bottom:0px;
    line-height: inherit;
    padding-left:20px;
    padding-right: 20px;
    border-radius: 12px;
    transition: 0.2s;
  }
  .layout-default.layout-signin header.ant-layout-header {
    position:fixed;
    top:0;
    left:0;
    right:0;
    z-index:2;
    background-color: #fafafa;
    padding: 0 16px;
  }
    }
    @media only screen 
    and (min-device-width : 768px) 
    and (max-device-width : 1024px)  { /* STYLES GO HERE */
      .select-order{
        width: 100% !important;
        position: absolute !important;
        right: 0px !important;
        height: 0% !important;
        padding-left: 10%!important;
        padding-right: 10% !important;
        padding-top: 100px !important;
    }
    .sign-img{
      display: none !important;
    }
    .tip_amount {
      margin-top: 1px !important;
      padding-left: 15px
    }
    .layout-dashboard .ant-layout-header {
      background: transparent;
      height: auto;
      padding-top:0px;
      padding-bottom:0px;
      padding-left:20px;
      padding-right: 20px;
      line-height: inherit;
      border-radius: 12px;
      transition: 0.2s;
    }
    .layout-default.layout-signin header.ant-layout-header {
      position:fixed;
      top:0;
      left:0;
      right:0;
      z-index:2;
      background-color: #fafafa;
      padding: 0 16px;
    }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .layoutContainer {
    height: calc(100vh - 20%);
    width: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow-y: auto;
  }
  .select-order{
    width: 100% !important;
    position: absolute !important;
    right: 0px !important;
    height: 0% !important;
    padding-left: 10%!important;
    padding-right: 10% !important;
    padding-top: 100px !important;
}
.sign-img{
  display: none !important;
}
.tip_amount {
  margin-top: 1px !important;
  padding-left: 15px
}
.layout-dashboard .ant-layout-sider.sider-primary {
  /* margin: 20px 0 0 20px; */
  /* padding: 20px 0px; */
  height: calc(100vh - 20px);
}
.MuiAccordionSummary-root {
  display: flex !important;
  padding: 0px 0px !important;
  min-height: 48px !important;
  transition: min-height 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
}
.sidebar-layout-header-name {
  font-weight: bold;
  font-Size: 24px;
  font-family: Arial,Helvetica,sans-serif!important;
}
.item-list {
  max-height: 70%;
  overflow: hidden;
  overflow-y: scroll;
}

.item-list-order-status {
  max-height: 300px;
  overflow: hidden;
  overflow-y: scroll;
  background-color: "red";
}

.MuiListItem-rootTotal {
  width: 100% !important;
  display: flex !important;
  position: relative !important;
  box-sizing: border-box !important;
  text-align: left !important;
  align-items: center !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  justify-content: flex-start !important;
  text-decoration: none !important;
}
.layout-dashboard .ant-layout-header {
  background: transparent;
  height: auto;
  padding: 0 20px;
  /* padding-top:0px;
  padding-bottom:0px; */
  line-height: inherit;
  border-radius: 12px;
  transition: 0.2s;
}
.layout-default.layout-signin header.ant-layout-header {
  position:fixed;
  top:0;
  left:0;
  right:0;
  z-index:2;
  background-color: #fafafa;
  padding: 0 16px;
}
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .layoutContainer {
    height: calc(100vh - 20%);
    width: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow-y: auto;
  }
  .select-order{
    width: 100% !important;
    position: absolute !important;
    right: 0px !important;
    height: 0% !important;
    padding-left: 10%!important;
    padding-right: 10% !important;
    padding-top: 100px !important;
}
.sign-img{
  display: none !important;
}
.tip_amount {
  margin-top: 1px !important;
  padding-left: 15px
}
.layout-dashboard .ant-layout-header {
  background: transparent;
  height: auto;
  padding: 0 20px;
  line-height: inherit;
  border-radius: 12px;
  transition: 0.2s;
}
.layout-default.layout-signin header.ant-layout-header {
  position:fixed;
  top:0;
  left:0;
  right:0;
  z-index:2;
  background-color: #fafafa;
  padding: 0 16px;
}
.layout-dashboard .ant-layout-sider.sider-primary {
  /* margin: 20px 0 0 20px; */
  padding: 20px 0px;
  height: calc(100vh - 20px);
}
.sidebar-layout-header-name {
  font-weight: bold;
  font-Size: 24px;
  font-family: Arial,Helvetica,sans-serif!important;
}

.item-list {
  max-height: 70%;
  overflow: hidden;
  overflow-y: scroll;
}

.item-list-order-status {
  max-height: 355px;
  overflow: hidden;
  overflow-y: scroll;
  background-color: "red";
}

.MuiListItem-rootTotal {
  width: 100% !important;
  display: flex !important;
  position: relative !important;
  box-sizing: border-box !important;
  text-align: left !important;
  align-items: center !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  justify-content: flex-start !important;
  text-decoration: none !important;
}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .layoutContainer {
    height: calc(100vh - 20%);
    width: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow-y: auto;
  }
  .select-order{
    width: 100% !important;
    position: absolute !important;
    right: 0px !important;
    height: 0% !important;
    padding-left: 10%!important;
    padding-right: 10% !important;
    padding-top: 100px !important;
}
.sign-img{
  display: none !important;
}
.tip_amount {
  margin-top: 1px !important;
  padding-left: 15px
}
.layout-dashboard .ant-layout-sider.sider-primary {
  /* margin: 20px 0 0 20px; */
  padding: 10px 0px;
  height: calc(100vh - 20px);
}
.layout-dashboard .ant-layout-header {
  background: transparent;
  height: auto;
  padding: 0 20px;
  line-height: inherit;
  border-radius: 12px;
  transition: 0.2s;
}
.layout-default.layout-signin header.ant-layout-header {
  position:fixed;
  top:0;
  left:0;
  right:0;
  z-index:2;
  background-color: #fafafa;
  padding: 0 16px;
}
}
@media only screen and (min-width: 992px) and (max-width: 1230px) {
  .select-order{
    width: 100% !important;
    position: absolute !important;
    right: 0px !important;
    height: 0% !important;
    padding-left: 10%!important;
    padding-right: 10% !important;
    padding-top: 100px !important;
}
.sign-img{
  display: none !important;
}
.tip_amount {
  margin-top: 15px !important;
  padding-left: 15px
}
.layout-dashboard .ant-layout-header {
  background: transparent;
  height: auto;
  padding: 0 20px;
  line-height: inherit;
  border-radius: 12px;
  transition: 0.2s;
}
.layout-default.layout-signin header.ant-layout-header {
  position:fixed;
  top:0;
  left:0;
  right:0;
  z-index:2;
  background-color: #fafafa;
  padding: 0 16px;
}
}
@media only screen and (min-width: 992px) and (max-width: 1260px) {
  .select-order{
    width: 100% !important;
    position: absolute !important;
    right: 0px !important;
    height: 0% !important;
    padding-left: 10%!important;
    padding-right: 10% !important;
    padding-top: 100px !important;
}
.sign-img{
  display: none !important;
}
.layout-dashboard .ant-layout-header {
  background: transparent;
  height: auto;
  padding: 0 20px;
  line-height: inherit;
  border-radius: 12px;
  transition: 0.2s;
}
.layout-default.layout-signin header.ant-layout-header {
  position:fixed;
  top:0;
  left:0;
  right:0;
  z-index:2;
  background-color: #fafafa;
  padding: 0 16px;
}
.ant-drawer.drawer-sidebar.ant-drawer-open {
display:none;
}
}
@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
  .select-order{
    width: 100% !important;
    position: absolute !important;
    right: 0px !important;
    height: 0% !important;
    padding-left: 20%!important;
    padding-right: 20% !important;
    padding-top: 225px !important;
}
.sign-img{
  display: none !important;
}
.layout-dashboard .ant-layout-header {
  background: transparent;
  height: auto;
  padding: 0 20px;
  line-height: inherit;
  border-radius: 12px;
  transition: 0.2s;
}
.layout-default.layout-signin header.ant-layout-header {
  position:fixed;
  top:0;
  left:0;
  right:0;
  z-index:2;
  background-color: #fafafa;
  padding: 0 16px;
}
.item-list {
  max-height: 95% !important;
  overflow: hidden !important;
  overflow-y: scroll !important;
}

.item-list-order-status {
  max-height: 100% !important;
  overflow: hidden !important;
  overflow-y: scroll !important;
}
.ant-drawer.drawer-sidebar.ant-drawer-open {
  display:none;
  }
}
@media only screen and (min-width: 570px) and (max-width: 740px) {
  .layoutContainer {
    height: calc(100vh - 20%);
    width: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow-y: auto;
  }
  .select-order{
    width: 100% !important;
    position: absolute !important;
    right: 0px !important;
    height: 0% !important;
    padding-left: 10%!important;
    padding-right: 10% !important;
    padding-top: 100px !important;
}
.sign-img{
  display: none !important;
}
.tip_amount {
  margin-top: 1px !important;
  padding-left: 15px
}
.layout-dashboard .ant-layout-header {
  background: transparent;
  height: auto;
  padding: 0 20px;
  line-height: inherit;
  border-radius: 12px;
  transition: 0.2s;
}
.layout-default.layout-signin header.ant-layout-header {
  position:fixed;
  top:0;
  left:0;
  right:0;
  z-index:2;
  background-color: #fafafa;
  padding: 0 16px;
}
}
@media only screen and (min-width: 742px) and (max-width: 767px) {
  .layoutContainer {
    height: calc(100vh - 20%);
    width: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow-y: auto;
  }
  .select-order{
    width: 100% !important;
    position: absolute !important;
    right: 0px !important;
    height: 0% !important;
    padding-left: 10%!important;
    padding-right: 10% !important;
    padding-top: 100px !important;
}
  .sign-img{
  display: none !important;
}
.tip_amount {
  margin-top: 1px !important;
  padding-left: 15px
}
.layout-dashboard .ant-layout-header {
  background: transparent;
  height: auto;
  padding: 0 20px;
  line-height: inherit;
  border-radius: 12px;
  transition: 0.2s;
}
.layout-default.layout-signin header.ant-layout-header {
  position:fixed;
  top:0;
  left:0;
  right:0;
  z-index:2;
  background-color: #fafafa;
  padding: 0 16px;
}
}
@media only screen and (min-width: 792px) and (max-width: 991px) {
  .select-order{
    width: 100% !important;
    position: absolute !important;
    right: 0px !important;
    height: 0% !important;
    padding-left: 10%!important;
    padding-right: 10% !important;
    padding-top: 100px !important;
}
.sign-img{
  display: none !important;
}
.tip_amount {
  margin-top: 1px !important;
  padding-left: 15px
}
.layout-dashboard .ant-layout-header {
  background: transparent;
  height: auto;
  padding: 0 20px;
  line-height: inherit;
  border-radius: 12px;
  transition: 0.2s;
}
.layout-default.layout-signin header.ant-layout-header {
  position:fixed;
  top:0;
  left:0;
  right:0;
  z-index:2;
  background-color: #fafafa;
  padding: 0 16px;
}
}
@media only screen and (min-width: 570px) and (max-width: 575px) {
  .layoutContainer {
    height: calc(100vh - 20%);
    width: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow-y: auto;
  }
  .select-order{
    width: 100% !important;
    position: absolute !important;
    right: 0px !important;
    height: 0% !important;
    padding-left: 10%!important;
    padding-right: 10% !important;
    padding-top: 100px !important;
}
.sign-img{
  display: none !important;
}
.tip_amount {
  margin-top: 1px !important;
  padding-left: 15px
}
.layout-dashboard .ant-layout-header {
  background: transparent;
  height: auto;
  padding: 0 20px;
  line-height: inherit;
  border-radius: 12px;
  transition: 0.2s;
}
.layout-default.layout-signin header.ant-layout-header {
  position:fixed;
  top:0;
  left:0;
  right:0;
  z-index:2;
  background-color: #fafafa;
  padding: 0 16px;
}
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .layoutContainer {
    height: calc(100vh - 20%);
    width: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow-y: auto;
  }
  .select-order{
    width: 100% !important;
    position: absolute !important;
    right: 0px !important;
    height: 0% !important;
    padding-left: 10%!important;
    padding-right: 10% !important;
    padding-top: 100px !important;
}
.sign-img{
  display: none !important;
}
.tip_amount {
  margin-top: 1px !important;
  padding-left: 15px
}
.layout-dashboard .ant-layout-header {
  background: transparent;
  height: auto;
  padding: 0 20px;
  line-height: inherit;
  border-radius: 12px;
  transition: 0.2s;
}
.layout-default.layout-signin header.ant-layout-header {
  position:fixed;
  top:0;
  left:0;
  right:0;
  z-index:2;
  background-color: #fafafa;
  padding: 0 16px;
}
}

